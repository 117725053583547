import "./style.scss";
import { Global } from "./common/global";
import { Server } from "./common/callServer";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
Global.source = urlParams.get("utm_source");

Global.serverObj = new Server();
Global.serverObj.send(
  Global.URL_CREATE,
  setUID.bind(this),
  null,
  {
    device: Global.isMobile ? "mobile" : "web",
    fresh: true,
    saveType: "create",
    source: Global.source,
  },
  "POST",
  null,
  false
);

function setUID(data) {
  // console.log(data, "gaggh");
  let v = JSON.parse(data);
  // console.log(v, "gaggh");
  Global.U_ID = v.uid;
  Global.gameKey = v.game_key;

  // console.log(Global.U_ID, " gaggh ", Global.gameKey);
}

document.addEventListener("DOMContentLoaded", function () {
  // const progressBar = document.getElementById("progress-bar");
  const progressText = document.getElementById("progress-text");
  const progressBarRed = document.getElementById("progress");
  const content = document.getElementById("mainContainer");

  document.querySelector(".loader-image").style.transform = "translateY(-10%)";

  let progress = 0;

  function updateProgress() {
    if (progress < 100) {
      progress += Math.random() * 10; // Simulate the progress increment
      if (progress > 100) progress = 100;
      progressBarRed.style.width = progress + "%";
      progressText.textContent = Math.floor(progress) + "%";
      setTimeout(updateProgress, 200); // Update progress every 200ms
    }
  }

  updateProgress();

  /*  window.onload = function () {
    progress = 100;
    progressBarRed.style.width = progress + "%";
    progressText.textContent = progress + "%";

    setTimeout(() => {
      document.getElementById("loader2").style.display = "none";
      content.style.display = "block";
      document.querySelector(".firstPageDiv").style.display = "block";
      setTimeout(() => {
        document.querySelector(".firstPageDiv").style.opacity = "1";
      }, 10);
    }, 500);

    
  }; */
  window.onload = function () {
    progress = 100;
    progressBarRed.style.width = progress + "%";
    progressText.textContent = progress + "%";

    setTimeout(() => {
      document.getElementById("loader2").style.display = "none";
      content.style.display = "block";
      document.querySelector(".firstPageDiv").style.display = "block";
      setTimeout(() => {
        document.querySelector(".firstPageDiv").style.opacity = "1";
      }, 10);
    }, 500);

    gtag("event", "conversion", {
      allow_custom_scripts: true,
      u21: Global.language,
      u5: Global.country,
      send_to: "DC-9227341/ae_bt0/engag0+standard",
    });

    document.getElementById("nscript").innerHTML =
      '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_bt0;cat=engag0;u5=' +
      Global.country +
      ";u21=" +
      Global.language +
      ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';

    /* gtag("event", "conversion", {
      allow_custom_scripts: true,
      u21: Global.language,
      u5: Global.country,
      send_to: "DC-9227341/ae_bt0/engag0+standard",
    }); */

    /*   document.getElementById("nscript").innerHTML =
    '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_or0;cat=inter0;u1=https://oreoarabia.com/;u21=' +
    Global.language +
    ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'; */

    document.querySelector(".languageSelDiv").addEventListener("click", () => {
      LanguageHandler();
    });

    document.body.addEventListener("click", () => {
      if (Global.firstTimeMusicPlayed === false) {
        document.getElementById("BgMUsic").play();
        document.getElementById("BgMUsic").loop = true;
        Global.firstTimeMusicPlayed = true;
      }
    });

    document.getElementById("HomeBtn").addEventListener("click", () => {
      Global.serverObj.send(
        Global.VALUE_UPDATER,
        null,
        null,
        {
          saveType: "homeBtn",
          uniqID: Global.U_ID,
          encryptkey: Global.gameKey,
        },
        "POST",
        null,
        false
      );
      // alert("Home");
      document.querySelector(".BackToHomeButton").classList.add("clicked");
      document.querySelectorAll(".BTH_color").forEach((elem) => {
        elem.classList.add("expanded");
      });
      location.reload();
    });

    document.getElementById("BgMUsic").loop = true;
    /*  document.getElementById("BgMUsic").play();
  document.getElementById("BgMUsic").loop = true; */

    /*CHeckBox Handlers  */
    document.querySelector(".check1").addEventListener("click", () => {
      if (Global.checkBox1 === false) {
        Global.checkBox1 = true;
        document.querySelector(".TnCTxt").style.opacity = "0";
        Global.firstTimeMusicPlayed = true;
        document.querySelector(".firstPageErr").style.opacity = "0";
        document.querySelector(".check1").src =
          "https://www.snackarabia.com/Assets2/tick.png";
      } else {
        Global.checkBox1 = false;
        document.querySelector(".check1").src =
          "https://www.snackarabia.com/Assets2/tick_box.png";
      }
    });
    document.querySelector(".check2").addEventListener("click", () => {
      if (Global.checkBox2 === "false") {
        Global.checkBox2 = "true";
        document.querySelector(".check2").src =
          "https://www.snackarabia.com/Assets2/tick.png";
      } else {
        Global.checkBox2 = "false";
        document.querySelector(".check2").src =
          "https://www.snackarabia.com/Assets2/tick_box.png";
      }
    });

    /* SoundBtn Handler */
    document.querySelector(".soundImg").addEventListener("click", () => {
      if (Global.sound === true) {
        Global.sound = false;
        document.getElementById("BgMUsic").pause();
        document.querySelector(".soundImg").src =
          "https://www.snackarabia.com/Assets2/sound.png";
      } else {
        document.getElementById("BgMUsic").play();
        document.getElementById("BgMUsic").loop = true;
        Global.sound = true;
        document.querySelector(".soundImg").src =
          "https://www.snackarabia.com/Assets2/mute.png";
      }
    });

    /* Proceed Button Handler */

    document.querySelector(".proceedBtnDiv").addEventListener("click", () => {
      proceedBtnHandler();
    });

    document.querySelector(".NextBtnDiv").addEventListener("click", () => {
      nextBtnHandler();
    });

    document.querySelector(".BackBtnDiv").addEventListener("click", () => {
      backBtnHandler();
    });

    // triggerFirstPageAnimation();

    // triggerThirdPageAnimation();

    // changeTranslations();

    /* Submit Button CLick Handler */
    document.querySelector(".SubmitBtnDiv").addEventListener("click", () => {
      submitBtnHandler();
    });

    document.getElementById("pc0").addEventListener("click", () => {
      window.open("https://snackarabia.com/privacy-policy/index.html");
    });
    document.getElementById("pc").addEventListener("click", () => {
      window.open("https://snackarabia.com/privacy-policy/index.html");
    });
    document.getElementById("pcArab").addEventListener("click", () => {
      window.open("https://snackarabia.com/privacy-policy/index.html");
    });

    /* TnCs text Click Handler */
    document
      .querySelector(".TnCBottomLeftTxt")
      .addEventListener("click", () => {
        if (Global.country == "" || Global.country == null) {
          document.querySelector(".firstPageErr").style.opacity = "1";
        } else {
          // document.querySelector(".firstPageDiv").style.display = "none";
          // document.querySelector(".SecondPageDiv").style.display = "block";

          document.querySelector(".popupOman_english").style.display = "none";
          document.querySelector(".popupOman_arabic").style.display = "none";
          document.querySelector(".popupKuwait_english").style.display = "none";
          document.querySelector(".popupKuwait_arabic").style.display = "none";
          document.querySelector(".popupBahrain_english").style.display =
            "none";
          document.querySelector(".popupBahrain_arabic").style.display = "none";
          document.querySelector(".popupQatar_english").style.display = "none";
          document.querySelector(".popupQatar_arabic").style.display = "none";
          document.querySelector(".popupUAE_english").style.display = "none";
          document.querySelector(".popupUAE_arabic").style.display = "none";

          document.querySelector(".popupBG").style.display = "block";
          // console.log(`popup${Global.country}_${Global.language}`);
          document.querySelector(
            `.popup${Global.country}_${Global.language}`
          ).style.display = "block";
          setTimeout(() => {
            document.querySelector(".popupBG").style.opacity = "1";
          }, 10);
        }
        /*  if (Global.country != "" || Global.country != null) {
      document.querySelector(".popupBG").style.display = "block";
      console.log(`popup${Global.country}_${Global.language}`);
      document.querySelector(
        `.popup${Global.country}_${Global.language}`
      ).style.display = "block";
      setTimeout(() => {
        document.querySelector(".popupBG").style.opacity = "1";
      }, 10);
    } else {
      
    } */
      });

    /* TnCs Click Handler */
    document.querySelector(".TnCCLick").addEventListener("click", () => {
      document.querySelector(".popupBG").style.display = "block";
      // console.log(`popup${Global.country}_${Global.language}`);
      document.querySelector(
        `.popup${Global.country}_${Global.language}`
      ).style.display = "block";
      setTimeout(() => {
        document.querySelector(".popupBG").style.opacity = "1";
      }, 10);
    });
    /* TnCs Click Handler */
    document.querySelector(".TnCCLick2").addEventListener("click", () => {
      document.querySelector(".popupBG").style.display = "block";
      // console.log(`popup${Global.country}_${Global.language}`);
      document.querySelector(
        `.popup${Global.country}_${Global.language}`
      ).style.display = "block";
      setTimeout(() => {
        document.querySelector(".popupBG").style.opacity = "1";
      }, 10);
    });

    /* Country Code Append Handler */

    const countryDropdown = document.querySelectorAll(".countryList");
    for (let i = 0; i < countryDropdown.length; i++) {
      countryDropdown[i].addEventListener("click", (e) => {
        setCountry(countryDropdown[i]);
      });
    }
  };
});

function setCountry(elem) {
  let CodeOfCountry = elem.getAttribute("data-value");
  // console.log("Global.elem", CodeOfCountry);
  Global.countryCode = CodeOfCountry;
  if (CodeOfCountry === "+968") {
    Global.country = "Oman";
    document.querySelector(".firstPageErr").style.opacity = "0";
    document.querySelector(".NumOfChildDIV").style.display = "block";
  } else if (CodeOfCountry === "+965") {
    Global.country = "Kuwait";
    document.querySelector(".firstPageErr").style.opacity = "0";
    document.querySelector(".NumOfChildDIV").style.display = "block";
  } else if (CodeOfCountry === "+973") {
    Global.country = "Bahrain";
    document.querySelector(".firstPageErr").style.opacity = "0";
    document.querySelector(".NumOfChildDIV").style.display = "block";
  } else if (CodeOfCountry === "+974") {
    Global.country = "Qatar";
    document.querySelector(".firstPageErr").style.opacity = "0";
    document.querySelector(".NumOfChildDIV").style.display = "none";
  } else if (CodeOfCountry === "+971") {
    Global.country = "UAE";
    document.querySelector(".firstPageErr").style.opacity = "0";
    document.querySelector(".NumOfChildDIV").style.display = "block";
  } else {
    Global.country = "";
    document.querySelector(".firstPageErr").style.opacity = "1";
  }
  document.querySelector(".cCode").innerHTML = CodeOfCountry;
}

function LanguageHandler() {
  // document.querySelector(".glowImg").style.display = "none";
  // clearInterval(firstPageTimeInterval);

  if (Global.language === "english") {
    Global.language = "arabic";
    Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "arabicBtn",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    );
  } else {
    Global.language = "english";
    Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "englishBtn",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    );
  }

  document.querySelector(
    ".languageImg"
  ).src = `https://www.snackarabia.com/Assets2/${Global.language}.png`;

  changeTranslations();

  // triggerFirstPageAnimation();
}

/* TRANSLATION HANDLER */
function changeTranslations() {
  // console.log("chnageeke");
  if (Global.language == "arabic") {
    /* document.querySelector(".tempInstImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/instructions/arb_text.png"; */

    document.querySelector(".EngcheckBoxtext").style.display = "none";
    document.querySelector(".arabcheckBoxtext").style.display = "block";

    document.querySelector(".selectTxt").innerHTML = "اختر بلدك";
    document.querySelector(".selectTxt").style.fontFamily = "Expo_Arabic_Bold";

    document.querySelector(".cntryTxt").innerHTML = "البلد";
    document.querySelector(".cntryTxt").style.fontFamily = "Expo_Arabic_Bold";

    document.querySelector(".proceedTxt").firstChild.textContent = "المتابعة";
    document.querySelector(".proceedTxt").style.fontFamily = "Expo_Arabic_Bold";

    document.querySelector(".nextTxt").firstChild.textContent = "التالي";
    document.querySelector(".nextTxt").style.fontFamily = "Expo_Arabic_Bold";

    document.querySelector(".backtoHomeTxt").firstChild.textContent =
      "العودة إلى الصفحة الرئيسية";
    document.querySelector(".backtoHomeTxt").style.fontFamily =
      "Expo_Arabic_Bold";

    /*  if (Global.isMobile) {
      document.querySelector(".backtoHomeTxt").style.fontSize = "1.5vh";
    } else {
      document.querySelector(".backtoHomeTxt").style.fontSize =
        "3vw !important";
    }
 */
    document.querySelector(".backtoHomeTxt").classList.add("backHomeArab");

    document.querySelector(".ThirdPage_SuccessTxt").innerHTML =
      "لقد قدمت دخولك بنجاح في السحب للفوز برسوم مدرسية وجوائز أخرى";
    document.querySelector(".ThirdPage_SuccessTxt").style.fontFamily =
      "Expo_Arabic_Bold";

    document.querySelector(".check2Txt").firstChild.textContent =
      "رغب في استلام رسائل ترويجية من موندليز حول منتجاتها وعروضها.";
    document.querySelector(".check2Txt").style.fontFamily =
      "GESSTextMedium_Medium";
    document.querySelector(".checkBox1Right").style.paddingLeft = "";
    document.querySelector(".checkBox2Right").style.paddingLeft = "";

    document.querySelector(".secondPageFooterLogoIMG").src =
      "https://www.snackarabia.com/Assets2/title2arabic.png";
    // document.querySelector(".checkBox1").style.flexDirection = "row-reverse";
    // document.querySelector(".checkBox2").style.flexDirection = "row-reverse";

    document.querySelector(".enterTxt").innerHTML = "أدخل بياناتك";
    document.querySelector(".enterTxt").style.fontFamily =
      "GESSTextMedium_Medium";
    document.getElementById("name").placeholder = "الاسم";

    document.getElementById("email").placeholder = "البريد الإلكتروني";
    // document.getElementById("email").style.direction = "rtl";
    document.getElementById("name").style.direction = "rtl";
    document.getElementById("name").style.textAlign = "right";
    document.getElementById("email").classList.add("ARABtxtPlace");
    document.getElementById("mobile").classList.add("ARABtxtPlace");
    document.querySelector(".childField").style.direction = "rtl";
    // document.getElementById("email").style.textAlign = "left";
    document.getElementById("mobile").placeholder = "رقم الهاتف";
    // document.getElementById("CountryCode").placeholder = "البلد";

    document.querySelector(".TnCBottomLeftTxt").innerHTML =
      "*البنود و تطبق الشروط.";
    document.querySelector(".TnCBottomLeftTxt").style.fontFamily =
      "GESSTextMedium_Medium";

    document.getElementById("childFieldID").options[0].text = "عدد الاطفال";
    document.getElementById("childFieldID").options[4].text = "3 أو أكثر";
    document.getElementById("childFieldID").style.fontFamily =
      "GESSTextMedium_Medium";

    document.getElementById("name").classList.add("arabFont");
    document.getElementById("email").classList.add("arabFont");
    document.getElementById("mobile").classList.add("arabFont");

    document.querySelector(".prizeImg").src =
      "https://www.snackarabia.com/Assets2/prizeArabic.png";
    document.querySelector(".prize2IMG").src =
      "https://www.snackarabia.com/Assets2/prizeArabic.png";
    /*  document.querySelector(".congratsImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/Vectorarabic.png"; */

    var countryArr = [
      "الإمارات العربية المتحدة",
      "الكويت",
      "مملكة البحرين",
      "سلطنة عمان",
      "قطر",
    ];
    for (let i = 1; i < 6; i++) {
      document.querySelector(`.cntry${i}`).innerHTML = countryArr[i - 1];
      document.querySelector(`.cntry${i}`).style.fontFamily =
        "GESSTextMedium_Medium";
    }

    // document.querySelector(".CountryDiv").style.direction = "rtl";

    /* document.querySelector(".CountryDiv").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.getElementById("CountryCode").style.fontFamily =
      "NotoSansArabic_Condensed_Medium"; */

    // document.querySelector(".mobileDiv").style.direction = "ltr";

    // console.log(document.getElementById("CountryCode").options[0].text, "OOOO");

    document.querySelector(".inputDiv").style.fontFamily =
      "GESSTextMedium_Medium";
    document.querySelector(".findUniqueTxt").style.fontFamily =
      "GESSTextMedium_Medium";

    document.querySelector(".checkboxDiv").style.direction = "rtl";
    // document.querySelector(".agreeTxt").firstChild.textContent = "أقبل";
    /* document.querySelector(".TnCCLick").firstChild.textContent =
      "الشروط والأحكام"; */
    /*  document.querySelector(".privacyTxt").firstChild.textContent =
      "إشعار الخصوصية";
    document.querySelector(".privacyTxt2").firstChild.textContent =
      "إشعار الخصوصية"; */
    /* document.querySelector(".ofTxt").firstChild.textContent =
      "لمونديليز والموافقة على استخدام Mondelez لبياناتي الشخصيةالمعلومات كماالمذكورة في الشروط والأحكام و"; */

    document.querySelector(".firstPageErr").firstChild.textContent =
      "يرجى اختيار بلدك";

    document.querySelector(".firstPageErr").style.fontFamily =
      "GESSTextMedium_Medium";
    document.querySelector(".TnCTxt").firstChild.textContent =
      "يرجى الموافقة على الشروط والأحكام";

    document.querySelector(".TnCTxt").style.fontFamily =
      "GESSTextMedium_Medium";
    document.querySelector(".err").style.fontFamily = "GESSTextMedium_Medium";

    document.querySelector(".checkboxDiv").style.fontFamily =
      "GESSTextMedium_Medium";

    document.querySelector(".SubmitTxt").firstChild.textContent = "إرسال";
    document.querySelector(".SubmitTxt").style.fontFamily =
      "GESSTextMedium_Medium";

    document.querySelector(".enterUniqueCodeTxt").style.fontFamily =
      "GESSTextMedium_Medium";

    document.querySelector(".enterUniqueCodeTxt").firstChild.textContent =
      "أدخل الرمز الفريد";
    document.querySelector(".findUniqueTxt").firstChild.textContent =
      "ابحث عن الرمز الفريد خلف الملصق على العبوة";

    /* document.querySelector(".lastPageBottomTxt").firstChild.textContent =
      "لقد قدمت بنجاح دخولك إلى";
    document.querySelector(".lastPageBottomTxt").style.fontFamily =
      "NotoSansArabic_Condensed_Medium"; */

    /*     document.querySelector(".lastPageBottomTxtBold").firstChild.textContent =
      " اربح نسخة محدودة من الذهبمضرب*";
    document.querySelector(".lastPageBottomTxtBold").style.fontFamily =
      "NotoSansArabic_Condensed_ExtraBold"; */

    // console.log(document.querySelector(".ofTxt").firstChild);
  } else {
    document.querySelector(".selectTxt").innerHTML = "SELECT YOUR COUNTRY";
    document.querySelector(".selectTxt").style.fontFamily = "Kopiku";
    document.querySelector(".checkboxDiv").style.fontFamily = "Kopiku";

    document.querySelector(".proceedTxt").firstChild.textContent = "Proceed";
    document.querySelector(".proceedTxt").style.fontFamily =
      "KGBlankSpaceSketch";
    document.querySelector(".backtoHomeTxt").firstChild.textContent =
      "BACK TO HOME";
    document.querySelector(".backtoHomeTxt").style.fontFamily =
      "KGBlankSpaceSketch";

    /*  if (Global.isMobile) {
      document.querySelector(".backtoHomeTxt").style.fontSize = "3vh";
    } else {
      document.querySelector(".backtoHomeTxt").style.fontSize =
        "5vw !important";
    } */
    document.querySelector(".backtoHomeTxt").classList.remove("backHomeArab");

    document.querySelector(".cntryTxt").innerHTML = "Country";
    document.querySelector(".cntryTxt").style.fontFamily = "KGBlankSpaceSketch";

    document.querySelector(".checkBox1").style.flexDirection = "row";
    document.querySelector(".checkBox2").style.flexDirection = "row";

    document.querySelector(".check2Txt").firstChild.textContent =
      "I would like to recieve promotional communication from Mondelez about its productsand offers by e-mail and SMS.";
    document.querySelector(".check2Txt").style.fontFamily = "Kopiku";

    document.querySelector(".nextTxt").firstChild.textContent = "NEXT";
    document.querySelector(".nextTxt").style.fontFamily = "Expo_Arabic_Bold";

    document.querySelector(".secondPageFooterLogoIMG").src =
      "https://www.snackarabia.com/Assets2/title2.png";

    document.querySelector(".ThirdPage_SuccessTxt").innerHTML =
      "YOU HAVE SUCCESSFULLY SUBMITTED YOUR ENTRY INTO THE DRAW TO WIN SCHOOL FEES AND OTHER PRIZES*";

    document.querySelector(".ThirdPage_SuccessTxt").style.fontFamily =
      "KGBlankSpaceSketch";

    document.querySelector(".checkBox1Right").style.paddingLeft = "10px";
    document.querySelector(".checkBox2Right").style.paddingLeft = "10px";

    document.getElementById("childFieldID").options[0].text =
      "NUMBER OF CHILDREN";
    document.getElementById("childFieldID").style.fontFamily = "Kopiku";

    document.getElementById("childFieldID").options[4].text = "3 or more";

    document.getElementById("name").classList.remove("arabFont");
    document.getElementById("email").classList.remove("arabFont");
    document.getElementById("mobile").classList.remove("arabFont");

    document.querySelector(".prizeImg").src =
      "https://www.snackarabia.com/Assets2/prize.png";
    document.querySelector(".prize2IMG").src =
      "https://www.snackarabia.com/Assets2/prize.png";
    // ABove  NEW
    /* document.querySelector(".tempInstImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/instructions/eng_text.png"; */
    /* document.querySelector(".congratsImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/congrats.png"; */
    document.querySelector(".EngcheckBoxtext").style.display = "block";
    document.querySelector(".arabcheckBoxtext").style.display = "none";

    /*  document.querySelector(".enterTxt").innerHTML =
      "Enter your details and unique number"; */
    document.querySelector(".checkboxDiv").style.direction = "ltr";
    document.querySelector(".enterTxt").innerHTML = "Enter your details";
    document.querySelector(".enterTxt").style.fontFamily = "PlutoCondMedium";

    document.querySelector(".TnCBottomLeftTxt").innerHTML =
      "*Terms & Conditions apply.";
    document.querySelector(".TnCBottomLeftTxt").style.fontFamily =
      "PlutoCondLight";

    document.getElementById("name").placeholder = "name";
    document.getElementById("email").placeholder = "email";
    document.getElementById("mobile").placeholder = "mobile";

    document.getElementById("name").style.direction = "ltr";
    document.getElementById("name").style.textAlign = "";
    document.getElementById("email").classList.remove("ARABtxtPlace");
    document.getElementById("mobile").classList.remove("ARABtxtPlace");
    document.querySelector(".childField").style.direction = "ltr";

    /*  document.querySelector(".CountryDiv").style.direction = "ltr";
    document.querySelector(".CountryDiv").style.fontFamily = "PlutoCondMedium"; */

    var EngCountryArr = ["UAE", "Kuwait", "Bahrain", "Oman", "Qatar"];
    /* for (let i = 0; i < 6; i++) {
      // document.getElementById("CountryCode").options[i].text = EngCountryArr[i];
    } */
    for (let i = 1; i < 6; i++) {
      document.querySelector(`.cntry${i}`).innerHTML = EngCountryArr[i - 1];
      document.querySelector(`.cntry${i}`).style.fontFamily =
        "KGBlankSpaceSketch";
    }

    /*    document.getElementById("CountryCode").style.fontFamily = "PLUTOHEAVY";
    document.getElementById("mobile").placeholder = "mobile"; */

    document.querySelector(".inputDiv").style.fontFamily = "PlutoCondMedium";
    document.querySelector(".findUniqueTxt").style.fontFamily =
      "PlutoCondLight";

    // document.querySelector(".checkboxDiv").style.direction = "ltr";
    document.querySelector(".agreeTxt").firstChild.textContent = "I accept the";
    document.querySelector(".TnCCLick").firstChild.textContent = " T&C ";
    /* document.querySelector(".privacyTxt").firstChild.textContent =
      "Privacy Notice";
    document.querySelector(".privacyTxt2").firstChild.textContent =
      "Privacy Notice"; */
    // document.querySelector(".ofTxt").firstChild.textContent =
    //   "of Mondelez and consent to Mondelez using my personal information as stated in the T&Cs and";

    document.querySelector(".firstPageErr").firstChild.textContent =
      "Please select your country.";
    document.querySelector(".firstPageErr").style.fontFamily =
      "KGBlankSpaceSketch";
    document.querySelector(".err").style.fontFamily = "KGBlankSpaceSketch";

    // document.querySelector(".checkboxDiv").style.fontFamily = "PlutoCondLight";

    document.querySelector(".SubmitTxt").firstChild.textContent = "Submit";
    document.querySelector(".SubmitTxt").style.fontFamily =
      "KGBlankSpaceSketch";

    document.querySelector(".enterTxt").style.fontFamily = "Kopiku";
    document.querySelector(".enterUniqueCodeTxt").style.fontFamily = "Kopiku";
    document.querySelector(".findUniqueTxt").style.fontFamily = "Kopiku";

    document.querySelector(".enterUniqueCodeTxt").firstChild.textContent =
      "Enter the unique code";
    document.querySelector(".findUniqueTxt").firstChild.textContent =
      "Find the unique code behind the sticker on the pack";
  }
}

function submitBtnHandler() {
  // goToCongratsPage();
  setTimeout(() => {
    document.getElementById("name").classList.remove("shakeClass");
    document.getElementById("email").classList.remove("shakeClass");
    document.getElementById("mobile").classList.remove("shakeClass");
    document.getElementById("uniqueCode").classList.remove("shakeClass");
    document.getElementById("childFieldID").classList.remove("shakeClass");
    // document.querySelector(".checkBox1").classList.remove("shakeClass");
    // document.getElementById("africanId").classList.remove("shakeClass");
    /* document.getElementById("dateofbirth").classList.remove("shakeClass");
    document.querySelector(".agreeTxt").classList.remove("shakeClass"); */
  }, 500);

  let nameVal = document.getElementById("name").value;
  let emailVal = document.getElementById("email").value;
  // let countryCodeVal = document.getElementById("CountryCode").value;
  let MobileVal = document.getElementById("mobile").value;
  let UniqueCodeValSmall = document.getElementById("uniqueCode").value;
  let UniqueCodeVal = UniqueCodeValSmall.toUpperCase();
  let childCount = document.getElementById("childFieldID").value;
  if (Global.country === "Qatar") {
    childCount = "0";
  }
  // console.log(childCount, "childCount", typeof childCount);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let tempData = null;
  var ga_cid = "1844306653.1689247851";
  ga_cid = getCookie2("_ga");

  if (typeof ga_cid === "undefined" || ga_cid === null) {
    ga_cid = "1844306653.1689247851";
  }

  var utm_term = urlParams.get("utm_term");
  var utm_medium = urlParams.get("utm_medium");
  var utm_source = urlParams.get("utm_source");
  var utm_content = urlParams.get("utm_content");
  window.htk = "";
  window.htk = getCookie("hubspotutk");

  /* console.log(
    "nameVal--",
    nameVal,
    "emailVal--",
    emailVal,
    "MobileVal--",
    MobileVal,
    "UniqueCodeVal--",
    UniqueCodeVal,
    "childCount",
    childCount
  ); */

  const mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,10}$/;
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (nameVal.length <= 0) {
    document.querySelector(".err").style.opacity = "1";
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Please enter your name.";
    } else {
      document.querySelector(".err").innerHTML = "يرجى إدخال اسمك";
    }

    document.getElementById("name").classList.add("shakeClass");
    return;
  } else if (emailVal == "" || emailVal == null) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Please enter your email";
    } else {
      document.querySelector(".err").innerHTML =
        "الرجاء إدخال بريدك الإلكتروني";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("email").classList.add("shakeClass");
    return;
  } else if (!regex.test(emailVal)) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Please enter a valid email";
    } else {
      document.querySelector(".err").innerHTML =
        "الرجاء إدخال بريد إلكتروني صالح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("email").classList.add("shakeClass");
    return;
  } else if (MobileVal == "" || MobileVal == null) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please enter your phone number";
    } else {
      document.querySelector(".err").innerHTML = "الرجاء إدخال رقم هاتفك";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("mobile").classList.add("shakeClass");

    return;
  } else if (!mobile_regx.test(MobileVal)) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please enter a valid mobile no.";
    } else {
      document.querySelector(".err").innerHTML = "الرجاء إدخال رقم جوال صالح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("mobile").classList.add("shakeClass");

    return;
  } else if (childCount == "") {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please select the number of children.";
    } else {
      document.querySelector(".err").innerHTML = "يرجى تحديد عدد الأطفال.";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("childFieldID").classList.add("shakeClass");

    return;
  } else if (UniqueCodeVal.length != 8) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please enter correct Unique Code.";
    } else {
      document.querySelector(".err").innerHTML =
        "الرجاء إدخال الرمز الفريد الصحيح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("uniqueCode").classList.add("shakeClass");
    return;
  } else if (
    !(
      UniqueCodeVal.startsWith("B") ||
      UniqueCodeVal.startsWith("V") ||
      UniqueCodeVal.startsWith("A") ||
      UniqueCodeVal.startsWith("R")
    )
  ) {
    /*   Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "invalid_coupon",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    ); */
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Invalid Coupon Code";
    } else {
      document.querySelector(".err").innerHTML = "رقم قسيمه غير صالح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("uniqueCode").classList.add("shakeClass");
    return;
  } else {
    document.querySelector(".err").style.opacity = 0;
    let newMobile =
      document.querySelector(".cCode").innerHTML + " " + MobileVal;

    Global.serverObj.send(
      Global.WEBSERVICE,
      (data) => {
        let parseData = JSON.parse(data);
        // console.log(parseData.coupon, "  data");
        fbq("track", "CompleteRegistration");
        ttq.track("CompleteRegistration");

        /* gtag("event", "conversion", {
          allow_custom_scripts: true,
          u21: Global.language,
          u5: Global.country,
          send_to: "DC-9227341/ae_bt0/engag0+standard",
        }); */
        gtag("event", "conversion", {
          allow_custom_scripts: true,
          u21: Global.language,
          u33: childCount,
          u5: Global.country,
          send_to: "DC-9227341/ae_bt0/conve0+standard",
        });

        document.getElementById("nscript").innerHTML =
          '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_bt0;cat=conve0;u5=' +
          Global.country +
          ";u21=" +
          Global.language +
          ";u33=" +
          childCount +
          ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';

        /*  document.getElementById("nscript").innerHTML =
          '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_bt0;cat=engag0;u5=' +
          Global.country +
          ";u21=" +
          Global.language +
          ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'; */

        /* gtag("event", "conversion", {
          allow_custom_scripts: true,
          u1: "https://snackarabia.com/",
          u21: Global.language,
          u23: Global.country,
          send_to: "DC-9227341/ae_or0/conve0+standard",
        }); */

        if (parseData.coupon == "Invalid") {
          /*  Global.serverObj.send(
            Global.VALUE_UPDATER,
            null,
            null,
            {
              saveType: "invalid_coupon",
              uniqID: Global.U_ID,
              encryptkey: Global.gameKey,
            },
            "POST",
            null,
            false
          ); */
          if (Global.language == "english") {
            document.querySelector(".err").innerHTML = "Invalid Unique code";
          } else {
            document.querySelector(".err").innerHTML = "رمز فريد غير صالح";
          }
          document.querySelector(".err").style.opacity = 1;
        } else {
          document.querySelector(".err").style.opacity = 0;
          goToCongratsPage();
        }
      },
      null,
      {
        saveType: "formData",
        uniqID: Global.U_ID,
        country: Global.country,
        name: nameVal,
        email: emailVal,
        mobile: newMobile,
        uniqueCode: UniqueCodeVal,
        child_count: childCount,
        utm_term: utm_term,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_content: utm_content,
        hubspotutk: window.htk,
        ga_cid: ga_cid,
        tc: Global.checkBox2,
      },
      "POST",
      null,
      false
    );
  }
}

function getCookie(cookie) {
  // console.log("getCookie");
  return document.cookie.split(";").reduce(function (prev, c) {
    var arr = c.split("=");
    return arr[0].trim() === cookie ? arr[1] : prev;
  }, undefined);
}
function getCookie2(cname) {
  // console.log("getCookie2");
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  // console.log(name);
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/* Proceeed Handler */
function proceedBtnHandler() {
  if (Global.country === "UAE") {
    location.href = "https://newsfeedsmartapp.com/Oreo_Barni/End/";
    return;
  }
  gtag("event", "conversion", {
    allow_custom_scripts: true,
    u21: Global.language,
    u5: Global.country,
    send_to: "DC-9227341/ae_bt0/inter0+standard",
  });

  document.getElementById("nscript").innerHTML =
    '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_bt0;cat=inter0;u5=' +
    Global.country +
    ";u21=" +
    Global.language +
    ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';

  // document.getElementById("ClickMUsic").play();
  Global.serverObj.send(
    Global.WEBSERVICE,
    {},
    null,
    {
      saveType: "saveCountry",
      uniqID: Global.U_ID,
      country: Global.country,
    },
    "POST",
    null,
    false
  );

  Global.currentScn = "TncPageDiv";
  Global.prevScn = "firstPageDiv";

  if (Global.country == "" || Global.country == null) {
    document.querySelector(".firstPageErr").style.opacity = "1";
  } else {
    document.querySelector(".proceedBtnDiv").classList.add("clicked");
    document.querySelectorAll(".proceed_color").forEach((elem) => {
      elem.classList.add("expanded");
    });
    document.querySelector(".firstPageDiv").style.opacity = "0";
    setTimeout(() => {
      document.querySelector(".TncPageDiv").style.display = "block";
      document.querySelector(".firstPageDiv").style.display = "none";
      document.querySelector(".TnCBottomLeftTxt").style.display = "block";
      document.querySelector(".BackBtnDiv").style.display = "block";
      triggerSecondPageAnimation();
      Global.sceneCount += 1;
      setTimeout(() => {
        document.querySelector(".TncPageDiv").style.opacity = "1";
      }, 10);
    }, 800);
  }
  resetForm();
}
function nextBtnHandler() {
  // console.log(Global.checkBox1, " Global.checkBox1");

  if (!Global.checkBox1) {
    document.querySelector(".TnCTxt").style.opacity = "1";
  } else {
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      u21: Global.language,
      u5: Global.country,
      send_to: "DC-9227341/ae_bt0/inter00+standard",
    });
    document.getElementById("nscript").innerHTML =
      '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_bt0;cat=inter00;u5=' +
      Global.country +
      ";u21=" +
      Global.language +
      ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';

    Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "next_btn",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    Global.currentScn = "SecondPageDiv";
    Global.prevScn = "TncPageDiv";
    document.querySelector(".TncPageDiv").style.opacity = "0";

    document.querySelector(".NextBtnDiv").classList.add("clicked");
    document.querySelectorAll(".next_color").forEach((elem) => {
      elem.classList.add("expanded");
    });
    setTimeout(() => {
      document.querySelector(".SecondPageDiv").style.display = "block";
      document.querySelector(".TncPageDiv").style.display = "none";
      clearInterval(Global.interval1);
      Global.sceneCount += 1;
      setTimeout(() => {
        document.querySelector(".SecondPageDiv").style.opacity = "1";
      }, 10);
    }, 800);
  }
}

function backBtnHandler() {
  Global.serverObj.send(
    Global.VALUE_UPDATER,
    null,
    null,
    {
      saveType: "back_btn",
      uniqID: Global.U_ID,
      encryptkey: Global.gameKey,
    },
    "POST",
    null,
    false
  );

  document.querySelector(".proceedBtnDiv").classList.remove("clicked");
  document.querySelectorAll(".proceed_color").forEach((elem) => {
    elem.classList.remove("expanded");
  });

  document.querySelector(".NextBtnDiv").classList.remove("clicked");
  document.querySelectorAll(".next_color").forEach((elem) => {
    elem.classList.remove("expanded");
  });

  document.querySelector(".SubmitBtnDiv").classList.remove("clicked");
  document.querySelectorAll(".submit_color").forEach((elem) => {
    elem.classList.remove("expanded");
  });

  clearInterval(Global.interval1);
  clearInterval(Global.interval2);
  document.querySelector(`.${Global.currentScn}`).style.opacity = "0";
  setTimeout(() => {
    document.querySelector(`.${Global.prevScn}`).style.display = "block";

    document.querySelector(`.${Global.currentScn}`).style.display = "none";
    setTimeout(() => {
      Global.sceneCount -= 1;
      document.querySelector(`.${Global.prevScn}`).style.opacity = "1";
    }, 10);
  }, 800);

  if (Global.sceneCount == 2) {
    Global.currentScn = "TncPageDiv";
    Global.prevScn = "firstPageDiv";
    document.querySelector(`.${Global.currentScn}`).style.opacity = "0";
    document.querySelector(`.${Global.prevScn}`).style.opacity = "0";
  } else if (Global.sceneCount == 3) {
    Global.currentScn = "SecondPageDiv";
    Global.prevScn = "TncPageDiv";

    document.querySelector(`.${Global.currentScn}`).style.opacity = "0";
    document.querySelector(`.${Global.prevScn}`).style.opacity = "0";
  } else if (Global.sceneCount == 3) {
    Global.currentScn = "thirdPageDiv";
    Global.prevScn = "SecondPageDiv";

    document.querySelector(`.${Global.currentScn}`).style.opacity = "0";
    document.querySelector(`.${Global.prevScn}`).style.opacity = "0";
  }

  if (Global.prevScn === "firstPageDiv") {
    document.querySelector(".BackBtnDiv").style.display = "none";
  }
}

function goToCongratsPage() {
  Global.currentScn = "thirdPageDiv";
  Global.prevScn = "SecondPageDiv";

  document.querySelector(".BackBtnDiv").style.display = "none";
  /* document.querySelector(".SecondPageDiv").style.display = "none";
  document.querySelector(".thirdPageDiv").style.display = "block"; */

  document.querySelector(".SecondPageDiv").style.opacity = "0";

  document.querySelector(".SubmitBtnDiv").classList.add("clicked");
  document.querySelectorAll(".submit_color").forEach((elem) => {
    elem.classList.add("expanded");
  });
  setTimeout(() => {
    Global.sceneCount += 1;
    document.querySelector(".thirdPageDiv").style.display = "block";
    document.querySelector(".SecondPageDiv").style.display = "none";
    triggerSecondPageAnimation2();
    setTimeout(() => {
      document.querySelector(".thirdPageDiv").style.opacity = "1";
    }, 10);
  }, 800);
}

/* var firstPageTimeInterval;
function triggerFirstPageAnimation() {
  let xx = 0;
  let winCnt = 0;
  firstPageTimeInterval = setInterval(() => {
    xx += 1;
    if (xx > 11) xx = 1;
    document.querySelector(
      ".firstPageOreoAnim"
    ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/oreo/${Global.language}/${xx}.png`;

    winCnt += 1;
    if (winCnt > 61) winCnt = 37;
    if (winCnt < 37) {
      document.querySelector(
        ".WinlogoAnim"
      ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/win/${Global.language}/${winCnt}.png`;
    } else {
      document.querySelector(".glowImg").style.display = "block";
      document.querySelector(
        ".glowImg"
      ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/win/glow/${winCnt}.png`;
    }
    // console.log(winCnt, "winCnt");
  }, 150);
} */

// var secondPageTimeInterval;
function triggerSecondPageAnimation() {
  let count = 0;
  let duration = 50;

  if (Global.country === "Qatar") {
    Global.interval1 = setInterval(() => {
      count++;
      // if (Global.language == "english") {
      if (count > 31) count = 27;
      duration = 100;
      /* } else {
    if (count > 20) count = 10;
    duration = 100;
  } */

      document.querySelector(
        ".winSchoolDivIMG"
      ).src = `https://www.snackarabia.com/Assets2/${Global.language}/Qatar/${count}.png`;
    }, duration);
  } else {
    Global.interval1 = setInterval(() => {
      count++;
      if (Global.language == "english") {
        if (count > 23) count = 14;
        duration = 100;
      } else {
        if (count > 20) count = 10;
        duration = 100;
      }

      document.querySelector(
        ".winSchoolDivIMG"
      ).src = `https://www.snackarabia.com/Assets2/${Global.language}/${count}.png`;
    }, duration);
  }
}

function triggerSecondPageAnimation2() {
  let count2 = 0;
  let duration = 50;

  if (Global.country === "Qatar") {
    Global.interval2 = setInterval(() => {
      count2++;
      if (count2 > 31) count2 = 27;
      duration = 100;

      document.querySelector(
        ".ThirdPage_WinSchoolIMG"
      ).src = `https://www.snackarabia.com/Assets2/${Global.language}/Qatar/${count2}.png`;
    }, duration);
  } else {
    Global.interval2 = setInterval(() => {
      count2++;
      if (Global.language == "english") {
        if (count2 > 23) count2 = 14;
        duration = 100;
      } else {
        if (count2 > 20) count2 = 10;
        duration = 100;
      }

      document.querySelector(
        ".ThirdPage_WinSchoolIMG"
      ).src = `https://www.snackarabia.com/Assets2/${Global.language}/${count2}.png`;
    }, duration);
  }
}

function resetForm() {
  document.getElementById("name").value = "";
  document.getElementById("email").value = "";
  document.getElementById("mobile").value = "";
  document.getElementById("uniqueCode").value = "";
  // let UniqueCodeVal = UniqueCodeValSmall.toUpperCase();
  document.getElementById("childFieldID").value = "";
}
