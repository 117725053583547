let Global = {
  language: "english",

  checkBox1: false,
  checkBox2: "false",

  country: null,

  sound: true,

  source: "Social",

  interval1: null,
  interval2: null,

  currentScn: "TncPageDiv",
  prevScn: "firstPageDiv",
  sceneCount: 1,

  countryCode: "",

  firstTimeMusicPlayed: false,

  U_ID: null,
  gameKey: null,

  startTheGame: false,

  URL_CREATE: "https://newsfeedsmartapp.com/Oreo_Barni/api/createUser.php",

  WEBSERVICE: "https://newsfeedsmartapp.com/Oreo_Barni/api/webservice.php",
  VALUE_UPDATER: "https://newsfeedsmartapp.com/Oreo_Barni/api/valueUpdator.php",
};
export { Global };
